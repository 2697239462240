<template>
  <main class="devices3 padded" style="padding-bottom:0;">
    <h2>Devices <input type="checkbox" v-model="adminDetails" style="margin-left: 20px; height: unset;"/> 
      <span style="color:gray;font-size:14px;">show all details</span>
      <span v-if="mdmloading" class="spinner" style="left:unset; margin: 2px 0 0 15px;"></span>
    </h2>

    <div class="formerror" v-if="error">{{error}}</div> 

    <div class="buttons">
      <select v-if="$store.getters.isAdmin" class="select-css" v-model="orgId" style="width: 200px;">
        <option :value=-1>No organization</option>
        <option v-for="org in organizations" v-bind:value="org.id" :key="org.id" :selected="org.id == $store.getters.organizationId">
          {{org.name}}
        </option>
      </select>
      <input v-model.trim="searchTerm" type="text" style="width: 250px;" :disabled="loading" autocomplete="off" placeholder="Search..." /> 
      <div style="display: flex">        
        <button class="white right" :disabled="loading" @click="addDeviceModal = true;">Add devices</button>
        <button class="white right" :disabled="loading || !$refs.datatable || $refs.datatable.selectedRows.length == 0" @click="assignExperienceModal = true;">Assign experience</button>
        <button class="white right" :disabled="loading || !$refs.datatable || $refs.datatable.selectedRows.length == 0" @click="unassignExperienceModal = true;">Remove experience</button>
        <button class="autowidth right" :disabled="loading" v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin" @click="syncModal = true;">
          <img v-if="syncing" style="height: 20px; vertical-align: middle; margin-right: 5px;" src="/spinner-white.svg">
          {{(syncing ? 'Sync in progress' : 'Refresh MDM in:')}}
        </button>
        <select style="margin-left:10px;" v-model="mdmAccount" :disabled="loading || !$store.getters.isAdmin" v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin">
          <option>Avris</option>
          <option>Insead</option>
        </select>
      </div>
    </div>  
    
    <VueGoodTable
      ref="datatable"
      :class="{showbulkactions: $refs.datatable && $refs.datatable.selectedRows.length > 0}"
      :columns="columns"
      :rows="devices"
      :row-style-class="rowStyleClassFn" 
      striped
      styleClass="vgt-table striped"
      :search-options="{
        enabled: false,
        externalQuery: searchTerm
      }"
      max-height="calc(100lvh - var(--size-header) - var(--main-offset-vertical))"
      :fixed-header="true"
      :select-options="{ 
        enabled: true,
        selectOnCheckboxOnly: true 
      }"
    >
      <template v-slot:selected-row-actions>  
        <button :disabled="loading || !$refs.datatable || !$refs.datatable.selectedRows.length" @click="showDeleteModal = true">Delete</button>           
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="!loading && props.column.field == 'actions'" class="rowActions">
          <!-- <button class="icon" :title="props.row.userId ? 'Sessions of device' : ''" @click="editSessions(props.row.userId)" :disabled="!props.row.userId" :style="`${!props.row.userId ? 'opacity:0;' : ''}`">
            <img src="../assets/removesessions.svg" style="width:16px;"  />
          </button> -->
          <button class="icon" title="Edit" @click="editRow(props.row.id)">
            <img src="../assets/page.svg" />
          </button>
          <button class="icon" title="Delete" @click="deleteRow(props.row.id)">
            <img src="../assets/delete.svg" />
          </button>
        </span>       
        <span v-else-if="props.column.field == 'lastLogin'">
          {{ props.row.lastLogin ? formatDate(new Date(props.row.lastLogin), $store.getters.timeZone) + ` ${toTimeZone(new Date(props.row.lastLogin), $store.getters.timeZone).getFullYear()}`: '-' }}
        </span>
        <span v-else-if="props.column.field == 'created'">
          {{ formatDate(new Date(props.row.created), $store.getters.timeZone) + ` ${toTimeZone(new Date(props.row.created), $store.getters.timeZone).getFullYear()}` }}
        </span>   
        <span v-else-if="props.column.field == 'experiences'" style="white-space: normal;">   
          <span v-for="e in props.row.experiences?.slice(0,6)" :key="e.experienceId" class="explabel">{{getExperienceName(e.experienceId)}}</span>    
          <span class="explabel" v-if="props.row.experiences?.length > 6">+{{props.row.experiences.length-6}}</span>
        </span> 
        <span v-else-if="props.column.field == 'syncStatus'" :title="props.row.syncStatus == 4 ? `We haven't heard from this device for a while. Please ensure it is powered on and connected to a power source and Wi-Fi.` : ''" class="tooltip white" :class="(props.row.experiences && props.row.experiences.length > 0) || (props.row.sessionExperiences && props.row.sessionExperiences.length > 0) ? ('sync ' + formatSyncStatus(props.row)) : ''">          
          <div class="tooltiptext" v-if="props.row.syncStatus == 5" style="text-align:left; width: 252px; white-space: normal;">
                Sync is paused for this device due to an upcoming or active session.  Sync will resume automatically 2 hours after the session ends.
          </div>
        </span> 
        <span v-else-if="props.column.field == 'freeStorage'">   
          {{props.row.freeStorage ? formatGB(props.row.freeStorage) : '?'}}/{{props.row.totalStorage ? formatGB(props.row.totalStorage) : '?'}}GB       
        </span> 
        <template v-else-if="props.column.field == 'mdm' && props.row.type != 'Quest'">                  
          <span v-if="!props.row.mdmId" style="color:red; font-weight:bold;">No MDM {{props.row.userId ? '' : '*'}}</span>                  
          <span v-else class="connection" :title="`MDM Id: ${props.row.mdmId}`">{{props.row.mdmStatus ? props.row.mdmStatus.connectionStatus : '--'}}</span>
        </template>
        <span v-else-if="props.column.field == 'mdmPower' && props.row.mdmStatus && props.row.type != 'Quest'" class="power" :class="getBatteryClass(props.row.mdmStatus)">
          {{`${props.row.mdmStatus.battery}%`}}
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </VueGoodTable>

    <span class="spinner relative" v-if="loading" />
    
    <Modal2 v-if="addDeviceModal" @close="addDeviceModal = false; error = ''" class="devicemodal">      
      <h3 slot="header">Add device</h3>
      <div slot="body" style="width: 336px; display: flex; flex-direction: column; height: 100%;"> 
        <!-- <div style="display: flex; flex-direction: column;">    -->
          <div style="flex-grow: 1;">
            <span style="color:#777;">Select the required device type you wise to add</span>
          </div> 
          <div style="text-align: center;">
            <img src="../assets/headset-drawing.jpg" style="width: 140px; margin-bottom: 30px;"/>
            <br>
            <button class="white" @click="addPicoModal = true; addDeviceModal = false;" style="margin-right: 25px;">Pico</button>
            <button class="white" @click="addQuestModal = true; addQuestStep = 1; addDeviceModal = false;">Quest</button>
          </div> 
          <div style="flex-grow: 2;"></div>      
        <!-- </div>        -->
      </div>
    </Modal2>   

    <ValidationObserver v-slot="{ invalid }" tag="div">
    <Modal2 v-if="addPicoModal" @close="addPicoModal = false; error = ''; resetAddDeviceFields();" class="devicemodal">      
      <h3 slot="header">Add PICO device</h3>
      <div slot="body" style="width: 336px;">

        <label v-if="$store.getters.isAdmin">Organization</label>
        <select v-if="$store.getters.isAdmin" class="select-css" v-model="newDevice.organizationId" :disabled="loading">
          <option :value=null selected>None</option>
          <option v-for="org in organizations" v-bind:value="org.id" :key="org.id">
            {{org.name}}
          </option>
        </select>

        <div style="margin-top:20px;">
          <input type="radio" style="vertical-align:middle; margin: 0;" v-model="bulkInvite" :value="false" /> <span style="vertical-align:middle;">Single device</span>
        </div>
        <ValidationProvider name="deviceSerial" rules="required" tag="div" v-slot="{ classes/*, errors*/ }">
          <label required>Serial number (Id)</label>
          <input v-model.trim="newDevice.id" :disabled="loading || bulkInvite" :class="classes" type="text"> 
        </ValidationProvider>
        <ValidationProvider name="deviceName" rules="required" tag="div" v-slot="{ classes/*, errors*/ }">
          <label required>Name</label>
          <input v-model.trim="newDevice.name" :disabled="loading || bulkInvite" :class="classes" type="text"> 
        </ValidationProvider>

        <div style="margin-top:20px;">
          <input type="radio" style="vertical-align:middle; margin: 0;" v-model="bulkInvite" :value="true" /> <span style="vertical-align:middle;">Multiple devices</span>
        </div>
        <textarea style="min-height: 100px;" :disabled="!bulkInvite || loading" v-model="newDevicesCsv" placeholder="Paste CSV content here, with 'Id' and 'Name' column order."></textarea>
        <div v-if="newDevicesCsv">          
          Found <b>{{newDevicesList ? newDevicesList.length : 0}}</b> valid devices:
          <br>
          <span class="newuseremail" v-for="device in newDevicesList" :key="device.id">
            {{device.id}} - {{device.name}}
          </span>
        </div>
        
        
      </div>
      <div slot="footer" style="width: 336px;">
        <div style="margin-bottom:30px;">
          <input type="checkbox" style="vertical-align:middle; margin: 0;" v-model="syncAfterCreate" :disabled="loading" /> <span style="vertical-align:middle;">Devices are already enrolled in <b v-if="$store.getters.isAdmin">{{mdmAccount}}</b> MDM</span>
          <br>
          <span v-if="syncAfterCreate" style="font-size: 14px; color:#d5d5d6;">Devices are automatically synced with MDM after creation. Only uncheck this if you are sure the device{{(bulkInvite ? 's are' : ' is')}} not yet enrolled in MDM.</span>
          <span v-else style="font-size: 14px; color:orange;">Devices added will not be synced automatically after creation with MDM! Only use this option if you are sure the device{{(bulkInvite ? 's are' : ' is')}} not yet enrolled. <b>Are you sure?</b></span>
        </div>
        <button class="right" @click="addPicoDevice" :disabled="(bulkInvite ? (!newDevicesList || newDevicesList.length == 0) : invalid) || loading">Add</button>    
        <button class="white right" @click="addPicoModal = false; resetAddDeviceFields();" :disabled="loading">Cancel</button>    
      </div>
    </Modal2>    
    </ValidationObserver>

    <ValidationObserver v-slot="{ invalid }" tag="div">
    <Modal2 v-if="addQuestModal" @close="addQuestModal = false; error = ''; resetAddDeviceFields();" class="devicemodal">      
      <h3 slot="header">Add Quest device</h3>
      <div slot="body" style="width: 336px;">

        <div class="steps" v-if="addQuestStep == 1 || addQuestStep == 2">
          <div class="step" :class="{active: addQuestStep == 1}">
            <span>1</span>
            <br>
            Enter details
          </div>
          <div class="step" :class="{active: addQuestStep == 2}">
            <span>2</span>
            <br>
            Pair device
          </div>
        </div>

        <div v-show="addQuestStep == 1">
          <label v-if="$store.getters.isAdmin">Organization</label>
          <select v-if="$store.getters.isAdmin" class="select-css" v-model="newDevice.organizationId" :disabled="loading">
            <option :value=null selected>None</option>
            <option v-for="org in organizations" v-bind:value="org.id" :key="org.id">
              {{org.name}}
            </option>
          </select>
        
          <ValidationProvider name="questDeviceName" rules="required" tag="div" v-slot="{ classes/*, errors*/ }">
            <label required>Name [TODO, will be prefixed with "Quest_" for now]</label>
            <input v-model.trim="newDevice.name" :disabled="loading" :class="classes" type="text" placeholder="Enter device name"> 
          </ValidationProvider>

          <label>Assign experiences [TODO, not implemented yet]</label>
          <vSelect            
            placeholder="Select experiences"
            name="questDeviceExperience"
            style="width:100%; clear:both;"
            :options="$store.getters.orderedExperiences" 
            :filter="experienceSearch" 
            :clearable="false"
            :reduce="exp => exp.id" 
            label="fileSizeLabel"
            v-model="questExperienceIds"
            multiple 
            :close-on-select="false"    
            :deselectFromDropdown="true"  
          >
            <template v-slot:option="exp">              
              {{experienceIds.includes(exp.id) ? '🗹' : '▢' }} {{exp.fileSizeLabel}}
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes"></span>
            </template> 
            <!-- <template #selected-option="{ name }">
              {{name.length > 35 ? `${name.substring(0,35)}...` : name}}
            </template>            -->              
          </vSelect>
        </div>

        <div v-show="addQuestStep == 2">
          <div style="color:#777; margin-bottom: 16px;">Enter the code displayed in the INSEAD XR app on your Quest headset.</div>
          <ValidationProvider name="questDevicePairingCode" mode="aggressive" rules="required|length:6" tag="div" v-slot="{ classes/*, errors*/ }">
            <label required>Pairing code</label>
            <input v-model.trim="newDevice.id" maxlength="6" :disabled="addQuestTimer || loading" :class="classes" type="text" placeholder="6 character pairing code"> 
          </ValidationProvider>

          <button v-if="!addQuestTimer" style="margin-top: 16px;" @click="addQuestDevice" :disabled="invalid || loading">{{addQuestTimer == 0 ? 'Try again' : 'Add'}}</button>
          <button v-else-if="addQuestTimer != null" class="white" style="margin-top: 16px;" @click="addQuestTimer = null;">Cancel</button>
          <!-- <Modal2 v-if="addQuestTimer != null" @close="addQuestTimer = null"> -->
            <div v-if="addQuestTimer" style="margin-top:20px;">
              <b>Waiting for confirmation</b>
              <br>
              To complete the paring process, go to your Quest headset and click Connect.
              <div style="padding: 20px; text-align: center;">
                <b style="font-size: 16px;">Time remaining</b>
                <br>
                <span style="font-size: 36px;">{{`${Math.floor(addQuestTimer / 60)}:${(addQuestTimer % 60).toString().padStart(2, '0')}`}}</span>
              </div>
            </div>
            <div v-else-if="addQuestTimer == 0" style="margin-top:20px; text-align: center;">            
              <img src="../assets/fi-3867499.svg" style="width: 100px; margin-bottom: 8px;"/>
              <div style="font-size: 16px;">This pairing session has expired. To reconnect try again.</div>            
            </div>
          <!-- </Modal2> -->
        </div>

        <div v-if="addQuestStep == 3" style="width: 336px; display: flex; flex-direction: column; margin-top: 100px;">         
          <div style="flex-grow: 1;"></div> 
          <div style="text-align: center;">
            <img src="../assets/headset-drawing.jpg" style="width: 140px; margin-bottom: 8px;"/>
            <div>Device <b>{{newDevice.name}}</b> was successfully assigned.</div>
          </div> 
          <div style="flex-grow: 2;"></div>    
        </div>
        
      </div>
      <div slot="footer" style="width: 336px;">    
        <div style="color:#777;">Pair your quest device</div>
        <div class="stepper">
          <div class="step" :class="{active: addQuestStep == 1}"><img src="../assets/enter-details.svg"><br>Enter details</div>
          <img src="../assets/arrow.svg">
          <div class="step" :class="{active: addQuestStep == 2}"><img src="../assets/get-the-code.svg"><br>Get the code</div>
          <img src="../assets/arrow.svg">
          <div class="step" :class="{active: addQuestStep == 2}"><img src="../assets/enter-the-code.svg"><br>Enter the code</div>
          <img src="../assets/arrow.svg">
          <div class="step" :class="{active: addQuestStep == 2}"><img src="../assets/confirm-pairing.svg"><br>Confirm pairing</div>
          <img src="../assets/arrow.svg">
          <div class="step" :class="{active: addQuestStep == 3}"><img src="../assets/pairing-complete.svg"><br>Pairing complete</div>
        </div>    
        <div v-if="addQuestStep == 1">
          <button class="right" @click="addQuestStep = 2;" :disabled="loading">Next &gt;</button>
          <button class="white right" @click="addQuestModal = false; resetAddDeviceFields();" :disabled="loading">Cancel</button>    
        </div>
        <div v-if="addQuestStep == 2">            
          <button class="white right" @click="addQuestStep = 1;" :disabled="addQuestTimer || loading">&lt; Back</button>
        </div>
        <div v-if="addQuestStep == 3">            
          <button class="white right" @click="addQuestStep = 1; addQuestModal = false; resetAddDeviceFields();" :disabled="loading">Close</button>
        </div>
      </div>
    </Modal2>    
    </ValidationObserver>

    <ValidationObserver v-slot="{ invalid }" tag="div">
    <Modal2 v-if="editDeviceModal" @close="editDeviceModal = false; error = ''" class="devicemodal">      
      <h3 slot="header">Edit device - {{tempDevice.type}}</h3>
      <div slot="body" style="min-width: 336px;">

        <label v-if="$store.getters.isAdmin">Organization</label>
        <select v-if="$store.getters.isAdmin" class="select-css" v-model="tempDevice.organizationId">
          <option :value=null selected>None</option>
          <option v-for="org in organizations" v-bind:value="org.id" :key="org.id">
            {{org.name}}
          </option>
        </select>

        <ValidationProvider name="deviceSerial" rules="required" tag="div" v-slot="{ /*classes/*, errors*/ }">
          <label required>Serial number (Id)</label>
          <div style="clear:both;">{{tempDevice.id}}</div>
          <!-- <input v-model.trim="tempDevice.id" :disabled="loading" :class="classes" type="text"> 
          <div v-if="tempDevice.id != tempDeviceIdBeforeEdit" style="font-size: 14px; color:#777; margin-top:5px;">If you update device serial number, the user will need to login again.</div> -->
        </ValidationProvider>
        <ValidationProvider name="deviceName" rules="required" tag="div" v-slot="{ classes/*, errors*/ }">
          <label required>Name</label>
          <input v-model.trim="tempDevice.name" :disabled="loading" :class="classes" type="text"> 
        </ValidationProvider>
        
        <table>
          <tr>
            <td>
              <label style="float:none;">Created</label>   
              <div>{{formatDate(new Date(tempDevice.created), $store.getters.timeZone) + `, ${toTimeZone(new Date(tempDevice.created), $store.getters.timeZone).getFullYear()}`}}</div>
            </td>
            <td style="padding-left:43px;">
              <label style="float:none;">Last seen</label>   
              <div>{{tempDevice.lastSeen ? formatDate(new Date(tempDevice.lastSeen), $store.getters.timeZone) + `, ${toTimeZone(new Date(tempDevice.lastSeen), $store.getters.timeZone).getFullYear()}` : '-'}}</div>
            </td>
          </tr>
        

          <tr v-if="$store.getters.isAdmin && tempDevice.type != 'Quest'">
            <td>        
              <label style="float:none;">MDM Account</label>   
              <div>{{tempDevice.mdmAccount ? tempDevice.mdmAccount : '-'}}</div>
            </td>
            <td style="padding-left:43px;">
              <label style="float:none;">MDM Id</label>   
              <div>{{tempDevice.mdmId ? tempDevice.mdmId : '-'}}</div>        
            </td>
          </tr>
        </table>

        <label style="float:none;">Free / total space</label>   
        <div>{{tempDevice.freeStorage ? formatGB(tempDevice.freeStorage) : '?'}} / {{tempDevice.totalStorage ? formatGB(tempDevice.totalStorage) : '?'}} GB</div>

        <div style="font-size:16px; margin-bottom:15px; margin-top:24px; font-weight:500;">Content</div>

        <label style="float:none;">Assigned experiences</label>   
        <div v-if="tempDevice.experiences && tempDevice.experiences.length" class="avristable">
          <table style="width:100%;">
            <tr>
              <th>Experience name</th>              
              <th>Files</th>
              <th style="text-align:center;">Actions</th>
              <th style="width:50px;">Sync</th>
            </tr>
            <tr v-for="exp in withFileDetails(tempDevice)" :key="exp.experienceId">
              <td>{{getExperienceName(exp.experienceId)}}</td>              
              <td>                
                <span v-if="exp.totalFileCount" class="files" @click="fileDetails = exp">{{exp.correctFiles.length}}/{{exp.totalFileCount}}</span>
                <span v-else>🛇</span>
              </td>
              <td style="text-align:center;">
                <button class="icon" title="Remove" :disabled="loading" @click="quickUnassign = exp">
                  <img src="../assets/delete.svg" style="width:20px;"/>
                </button>
              </td>
              <td style="text-align:center; width: 50px;">
                <span :class="'sync notext ' + formatExpSyncStatus(tempDevice, exp)"></span>
                <span class="progress"> {{exp.progress}}%</span>
              </td>
            </tr>
          </table> 
        </div>
        <div v-else>-</div>

        <label style="float:none;">Scheduled sessions</label>   
        <div class="avristable" v-if="tempDevice && tempDevice.sessions && tempDevice.sessions.length > 0">
          <table style="width:100%;" >
            <tr>
              <th>ID</th>
              <th>Session name</th>
              <th>Experience</th>
              <th style="width:50px;">Sync</th>
            </tr>
            <tr v-for="session in tempDevice.sessions" :key="session.id" class="tooltip white"> 
              <td>{{session.id}}</td>
              <td>{{session.name}}</td>
              <td>{{getExperienceName(session.experienceId)}}</td>
              <!-- <td>{{session.startDate ? formatDate(new Date(session.startDate), $store.getters.timeZone) : '-'}}</td> -->
              <td style="text-align:center; width:50px;">
                <span v-if="session.sync" :class="'sync notext ' + formatExpSyncStatus(tempDevice, tempDevice.sessionExperiences.find(se => se.sessionId == session.id && se.experienceId == session.experienceId), session.sync && session.syncPaused)"></span>
                {{session.sync ? '' : 'Off'}}
                <span v-if="session.sync" class="progress"> {{(tempDevice.sessionExperiences.find(se => se.sessionId == session.id && se.experienceId == session.experienceId)).progress}}%</span>
              </td>
              <div class="tooltiptext" style="text-align:left;">
                <b>{{session.name}}</b>
                <div v-if="session.startDate">{{formatDate(new Date(session.startDate), $store.getters.timeZone)}}</div>
                <div v-if="session.endDate">{{formatDate(new Date(session.endDate), $store.getters.timeZone)}}</div>                
              </div>
            </tr>
          </table>           
        </div>
        <div v-else>-</div>

        <div style="font-size:14px; color: #606266; margin-top:12px;">
          <span class="sync ready"></span>
          <span class="sync error" style="margin-left: 12px;"></span>
          <span class="sync paused" style="margin-left: 12px;"></span>
          <span class="sync syncing" :class="{badconn: tempDevice.networkStatus}" style="margin-left: 12px;"></span>
          <span class="sync pending" style="margin-left: 12px;"></span>
        </div>
         
      </div>
      <div slot="footer">
        <button class="right" @click="updateDevice" :disabled="invalid || updateBtnDisabled">Update</button>    
        <button class="white right" @click="editDeviceModal = false;">Cancel</button>    
      </div>
    </Modal2>    
    </ValidationObserver>

    <Modal2 v-if="showDeleteModal" @close="showDeleteModal = loading; error = '';" class="deletedevicemodal center">      
      <h3 slot="header">Delete devices</h3>
      <div slot="body">   
        {{$refs.datatable.selectedRows.length}} selected devices will be removed. 
        Any data (results, recordings) will be kept for potential future use, but the info from which device the data originated from will be lost. 
        These data sets will not be available from the dashboard UI.
        <h5 v-if="deviceDeleteCount && loading">Deleting device {{deviceDeleteCount}}/{{$refs.datatable.selectedRows.length}}</h5>
        <h5 v-if="deviceDeleteCount == $refs.datatable.selectedRows.length && !loading">Devices deleted successfully</h5>             
      </div>
      <div slot="footer">        
        <button v-if="!deviceDeleteCount" class="right red" @click="removeDevices" :disabled="loading">Delete devices</button>     
        <button v-if="!deviceDeleteCount" class="right white" @click="showDeleteModal = false; error = '';" :disabled="loading">Cancel</button> 
        <span class="spinner relative" v-if="loading" /> 
      </div>
    </Modal2>  

    <!-- <Modal2 v-if="showUserSessionsModal" @close="showUserSessionsModal = false; error = ''" class="usersessionsmodal center">      
      <h3 slot="header">
        <img src="../assets/removesessions.svg" style="width: 18px; margin-right:10px; vertical-align:middle;" />
        Assigned sessions of {{tempDevice.name}}</h3>
      <div slot="body">
        <table v-if="userSessions.length">
        <tr v-for="session in userSessions" :key="session.id"> 
          <td>{{session.id}} - </td> 
          <td>{{session.name}}</td>
        </tr>
        </table>
        <span v-else>Device has no sessions assigned</span>
        <div class="formerror" v-if="error">{{error}}</div>         
      </div>
      <div slot="footer">        
        <button class="right" @click="unassignAllSessions" :disabled="loading || !userSessions.length">Remove from all sessions</button>        
        <button class="white right" @click="showUserSessionsModal = false;">Cancel</button>
      </div>
    </Modal2>   -->

    <Modal2 v-if="syncModal" @close="syncModal = false;" class="syncmodal center">      
      <h3 slot="header">
        <img src="../assets/cloud-sync.svg" style="width: 30px; margin-right:10px; vertical-align:middle;" />
        Synchronize with MDM</h3>
      <div slot="body">
        Synchronizing with the MDM means the system will look for the device by it's <b>serial number</b> in the MDM service, and get / update the MDM Id of the device here in the XR Platform.
        <br>
        <br>
        This is only necessary if some devices don't have this MDM Id yet, or if the device was re-enrolled in the MDM service (and thus the MDM identifier has changed).
        <br>
        <br>
        This operation can take some time to complete. If you close the browser before it has finished, the operation will still continue in the cloud, and complete eventually.
      </div>
      <div slot="footer">        
        <button class="right" @click="syncWithMdm(); syncModal = false;" :disabled="loading">Start syncing all devices</button>        
        <button class="white right" @click="syncModal = false;">Cancel</button>
      </div>
    </Modal2> 

    <Modal2 v-if="assignExperienceModal" @close="closeAssignModal" class="expmodal center">      
      <h3 slot="header">Assign experiences to the selected devices</h3>
      <div slot="body" class="expassign">        
        <div class="leftside">
          <div>
            <div style="color:#a2a2a2; margin-bottom:10px;">{{experienceIds.length > 0 ? experienceIds.length : 'No'}} experiences selected {{experienceIds.length > 0 ? ` - ${humanFileSize(totalPackageSize)}` : ''}}</div>
            <vSelect            
              placeholder="Select experiences"
              name="deviceExperience"
              style="width:100%; clear:both;"
              :options="$store.getters.orderedExperiences" 
              :filter="experienceSearch" 
              :clearable="false"
              :reduce="exp => exp.id" 
              label="fileSizeLabel"
              v-model="experienceIds"
              multiple 
              :close-on-select="false"    
              :deselectFromDropdown="true"  
            >
              <template v-slot:option="exp">              
                {{experienceIds.includes(exp.id) ? '🗹' : '▢' }} {{exp.fileSizeLabel}}
              </template>
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes"></span>
              </template> 
              <!-- <template #selected-option="{ name }">
                {{name.length > 35 ? `${name.substring(0,35)}...` : name}}
              </template>            -->              
            </vSelect>
            <button style="margin-top:24px;" @click="assignExperiences();" :disabled="loading || experienceIds.length == 0 || assignDone || assignExperienceDisabled">Assign now</button>   
            <div v-if="assignExperienceDisabled" style="color:#777; display:flex; margin-top:10px;">              
              <img src="../assets/alert-red.svg" style="width: 20px; margin-right: 10px;" />              
              <div>There's not enough free space available on all of the selected devices. Remove those devices on the right side, or deselect some experiences so that the total package size will fit on all selected devices.</div>              
            </div>         
          </div>
          <div style="text-align:center; flex-grow: 1; display: flex; flex-direction: column; justify-content: end; align-items: center;" v-if="assignDone">
            <img src="../assets/updated.svg" style="width:60px;">
            <div style="color:#777; font-size:18px;">Syncing in progress</div>
          </div>
        </div>
        <div class="note">
          <b>Note</b>
          <p style="color: #797d7e;">Assign specific experiences to devices. As long as the experience is assigned to the device on this screen, the content will always stay on this device, regardless of scheduled sessions. Any change in the experience will be automatically updated.</p>
        </div>
        <div class="rightside">
          <div style="color:#a2a2a2; margin-bottom:10px;">{{$refs.datatable.selectedRows.length}} devices selected</div>
          <div class="avristable">
          <table>
            <tr>
              <th>Name</th>
              <th>Free space</th>
              <th>Remove</th>
            </tr>
            <tr v-for="device in $refs.datatable.selectedRows" :key="device.id" class="tooltip white">
              <td>{{device.name}}</td>
              <td :class="{nospace: notEnoughSpace(device)}">{{device.freeStorage ? humanFileSize(device.freeStorage) : '-'}}</td>
              <td>
                <button class="icon" title="Remove" :disabled="loading || assignDone" @click="removeExpDevice(device.id)">
                  <img src="../assets/remove.svg" />
                </button>
              </td>
              <div class="tooltiptext" style="text-align:left;" v-if="(device.experiences && device.experiences.length > 0) || (device.sessionExperiences && device.sessionExperiences.length > 0)">
                <b>Current content for {{device.name}}:</b>    
                <div v-if="device.experiences && device.experiences.length > 0" style="margin-top:8px;">Assigned permanently:
                  <div v-for="exp in device.experiences" :key="exp.eperienceId"> - {{getExperienceName(exp.experienceId)}}</div>              
                </div>
                <div v-if="device.sessionExperiences && device.sessionExperiences.length > 0" style="margin-top:8px;">Temporarily from sessions:
                  <div v-for="exp in device.sessionExperiences" :key="exp.eperienceId"> - {{getExperienceName(exp.experienceId)}}</div>  
                </div>            
              </div>
              <div v-else class="tooltiptext" style="text-align:left;"><b>No content for {{device.name}}</b></div>
            </tr>
          </table> 
          </div>         
        </div>
      </div>
      <div slot="footer">        
        <button class="white right" :disabled="loading" @click="closeAssignModal">Close</button>
                
      </div>
    </Modal2> 

    <Modal2 v-if="unassignExperienceModal" @close="closeUnassignModal" class="expremovemodal center">      
      <h3 slot="header"><img src="../assets/alert.svg" style="width: 24px;" />Remove experiences</h3>
      <div slot="body" class="expunassign">  
        <div style="margin-bottom:20px;">This action will remove the selected experiences from the {{$refs.datatable.selectedRows.length}} chosen devices.</div>
        <vSelect            
          placeholder="Select experiences"
          name="deviceExperience"
          style="width:100%; clear:both;"
          :options="unassignExperienceList" 
          :filter="experienceSearch" 
          :clearable="false"
          :reduce="exp => exp.id" 
          label="name"
          v-model="experienceIds"
          multiple 
          :close-on-select="false"    
          :deselectFromDropdown="true"  
        >
          <template v-slot:option="exp">              
            {{experienceIds.includes(exp.id) ? '🗹' : '▢' }} {{exp.name}}
          </template>
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes"></span>
          </template>             
        </vSelect>

        <div v-if="contentStillAssignedThroughSession">          
          <br>
          <b style="font-size:14px;">Note</b>
          <br>
          <div style="color: #797d7e;">Some devices are still assigned to sessions using this experience, it will be removed from the devices when the session expires.
            <img src="../assets/help.svg" style="width:16px; cursor: help;" title="Devices are automatically removed from sessions 7 days after its end date"  />            
          </div>        
        </div>
      </div>
      <div slot="footer">        
        <button class="right" @click="unassignExperiences();" :disabled="loading || experienceIds.length == 0 || assignDone">Remove now</button> 
        <button class="white right" :disabled="loading" @click="closeUnassignModal">Cancel</button>                
      </div>
    </Modal2> 

    <Modal2 v-if="fileDetails" @close="fileDetails = false;">      
      <h3 slot="header">File level details:</h3>
      <div slot="body">
        <table v-if="fileDetails.brokenFiles.length > 0">
            <tr style="text-align:left;">
              <th>File</th>
              <th>Server Date</th>
              <th>Device Date</th>
              <th>Reason</th>
            </tr>                      
            <tr v-for="file in fileDetails.brokenFiles" :key="file.key">                
              <td>{{file.key}}</td>
              <td>{{file.serverLastModified}}</td>
              <td>{{file.lastModified ? file.lastModified : ''}}</td>
              <td>{{file.reason}}</td>
            </tr>         
        </table> 
        <div v-else>
          Today is a good day, all content is fine on this device 
          <br>
          (• ◡•)
        </div>
      </div>
      <div slot="footer">               
        <button class="white" @click="fileDetails = false">Close</button>
      </div>
    </Modal2> 

    <Modal2 v-if="quickUnassign" @close="quickUnassign = false;" class="center">      
      <h3 slot="header"><img src="../assets/delete.svg" style="width: 24px; margin-right:12px; vertical-align:middle;" />Remove experience</h3>
      <div slot="body">
        {{getExperienceName(quickUnassign.experienceId)}} content will be removed from the device.
        <br>
        <br>
        Don't worry, if this device is assigned to a session using this content, it will remain on the device until the session expires.
      </div>
      <div slot="footer">               
        <button class="right" @click="quickUnassignExperience">Remove</button>
        <button class="right white" @click="quickUnassign = false">Close</button>
      </div>
    </Modal2> 
 
    <Snackbar ref="snackbar" /> 
  </main>
</template>

<script>
import axios from 'axios';
import Papa from 'papaparse';
import { VueGoodTable } from 'vue-good-table';
import Modal2 from '../components/Modal2.vue'
import Snackbar from '@/components/Snackbar.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@/veevalidate.js'
import { formatDate, toTimeZone, humanFileSize } from '@/utils.js'
import vSelect from 'vue-select'
import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr'

export default {
  name: 'Devices3',
  components: {
    VueGoodTable, 
    Modal2,
    Snackbar,
    ValidationProvider, 
    ValidationObserver,
    vSelect
  },
  watch:{
    orgId: async function(){      
      await this.getDevices()
      await this.refreshMdm(true)
    },
    '$store.state.experiences': function() {
      this.setExperiencesFilters()
    },  
    adminDetails(show){
      for (let i = 0; i < this.columns.length; i++) {
        const col = this.columns[i];
        let detailedColumns = ['appVersion', 'mdmAccount', 'mdmId', 'lastLogin', 'created', 'lastSeen']
        if(this.$store.getters.isAdmin)
          detailedColumns.push('userId')
        if(detailedColumns.includes(col.field))
          this.$set(this.columns[i], 'hidden', !show)
      }
    }
  },
  data: function(){
    return {
      anyad: '',
      columns: [        
        {
          label: 'Id',
          field: 'id',
        },
        {
          label: 'Technical User Id',
          field: 'userId',
          hidden: true
        },
        {
          label: 'Name',
          field: 'name',
        },  
        {
          label: 'Type',
          field: 'type',
          filterOptions: {
            enabled: true,
            placeholder: 'No filter', 
            filterDropdownItems: ['Pico','Quest']  
          }
        }, 
        {
          label: 'App Version',
          field: 'appVersion',          
          hidden: true
        },    
        {
          label: 'Last login',
          field: 'lastLogin',
          hidden: true
        },
        {
          label: 'Created',
          field: 'created',
          hidden: true
        }, 
        {
          label: 'MDM Account',
          field: 'mdmAccount',
          sortable: false,
          hidden: true
        },
        {
          label: 'MDM Id',
          field: 'mdmId',
          sortable: false,
          hidden: true
        },
        {
          label: 'Connection',
          field: 'mdm',
          sortable: false
        },
        {
          label: 'Power',
          field: 'mdmPower',
          sortable: false
        }, 
        {
          label: 'Scheduled sessions',
          field: 'sessionCount'
        }, 
        {
          label: 'Assigned experiences',
          field: 'experiences',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'No filter', 
            filterDropdownItems: this.$store.getters.orderedExperiences.map(e => ({text: e.name, value: e.id})),
            filterFn: this.expFilterFn             
          }
        }, 
        {
          label: 'Last seen',
          field: 'lastSeen',
          hidden: true
        },
        {
          label: 'Sync status',
          field: 'syncStatus',
          filterOptions: {
            enabled: true,
            placeholder: 'No filter', 
            filterDropdownItems: [
              {text: 'Awaiting Sync', value: 0}, 
              {text: 'Syncing', value: 1}, 
              {text: 'Sync Issue', value: 2}, 
              {text: 'Up-to-date', value: 3},
              {text: 'Requires action', value: 4},
              {text: 'Paused', value: 5}
            ]
          }
        }, 
        {
          label: 'Free Space',
          field: 'freeStorage'
        },         
        {
          label: 'Actions',
          field: 'actions',
          sortable: false
        },
      ],      
      newDevicesCsv: '',
      devices: [],
      organizations: [],
      //userSessions: [],
      loading: false,
      syncing: false,
      mdmAccount: 'Avris',
      error: undefined,
      searchTerm: undefined,
      addPicoModal: false,   
      editDeviceModal: false, 
      showDeleteModal: false,   
      //showUserSessionsModal: false,   
      deviceDeleteCount: undefined,
      newDevice: {
        id: undefined,
        name: undefined,
        organizationId: null
      },
      bulkInvite: false,       
      tempDeviceIdBeforeEdit: undefined,
      tempDeviceNameBeforeEdit: undefined,
      tempDeviceOrganizationIdBeforeEdit: null,
      tempDevice: {
        id: undefined,
        name: undefined,
        organizationId: null
      }, 
      syncModal: false,
      syncAfterCreate: true,
      orgId: this.$store.getters.organizationId ?? -1,
      adminDetails: false,
      assignExperienceModal: false,
      unassignExperienceModal: false,
      quickUnassign: false,
      experienceIds: [],
      assignDone: false,
      tickerInterval: null,
      mdmloading: false,
      fileDetails: undefined,
      addQuestModal: false,
      addQuestTimer: null,
      addQuestTimerInterval: null,
      connection: undefined,
      addDeviceModal: false,
      addQuestStep: 1,
      questExperienceIds: []
    }
  },
  computed: {     
    assignExperienceDisabled(){
      return this.$refs.datatable.selectedRows.find(d => this.notEnoughSpace(d))
    },
    totalPackageSize(){
      return this.$store.getters.orderedExperiences?.filter(e => this.experienceIds.includes(e.id))?.reduce(( previousValue, currentValue ) => previousValue + currentValue.size, 0) ?? 0  
    }, 
    updateBtnDisabled(){
      return this.loading || !this.tempDevice.id || !this.tempDevice.name || (this.tempDevice.id == this.tempDeviceIdBeforeEdit && this.tempDevice.name == this.tempDeviceNameBeforeEdit && this.tempDevice.organizationId == this.tempDeviceOrganizationIdBeforeEdit)
    },
    // selectedDevices(){      
    //   return this.$refs.datatable.selectedRows   
    // },
    newDevicesList(){
      return (this.bulkInvite ? Papa.parse(this.newDevicesCsv, {header: false, skipEmptyLines: true}).data.map(d => ({id: d[0]?.trim(), name: d[1]?.trim(), organizationId: this.newDevice.organizationId })) : [this.newDevice])        
        .filter(d => d.id && d.name)
    },
    unassignExperienceList(){
      return this.$store.getters.orderedExperiences.filter(e => this.$refs.datatable.selectedRows.find(r => r.experiences.find(x => x.experienceId == e.id)))
    },
    contentStillAssignedThroughSession(){
      return this.$refs.datatable?.selectedRows?.find(d => d.sessionExperiences?.find(e => this.experienceIds.includes(e.experienceId))) ? true : false
    }
  },
  methods: {  
    getExperienceName(id){
      return this.$store.getters.orderedExperiences.find(e => e.id == id)?.name ?? id
    },
    humanFileSize(size){
      return humanFileSize(size)
    },
    notEnoughSpace(device){
      if(device.type == 'Quest')
        return false
      if(device.freeStorage == null)
        return true

      const adjustedSpace = device.freeStorage + (this.$store.getters.orderedExperiences
        ?.filter(e => this.experienceIds.includes(e.id) && (device.experiences.find(de => de.experienceId == e.id) || device.sessionExperiences.find(se => se.experienceId == e.id)))
        ?.reduce(( previousValue, currentValue ) => previousValue + currentValue.size, 0) ?? 0)   
              
      return adjustedSpace < this.totalPackageSize
    },
    toTimeZone(d, tz){ return toTimeZone(d,tz) }, 
    formatDate(d, tz){ return formatDate(d, tz) },
    formatSyncStatus(device){      
      switch(device.syncStatus){
        case 0: return 'pending';
        case 1: return 'syncing' + (device.networkStatus ? ' badconn' : '');
        case 2: return 'error';
        case 3: return 'ready';
        case 4: return 'action';
        case 5: return 'paused';
      }
    },
    formatExpSyncStatus(device, deviceExperience, paused){
      if(paused || device.syncPaused || device.sessions?.find(x => x.syncPaused)) return 'paused'
      else if(!deviceExperience) return 'pending'  // this is only for backward compatibility where there are no progress tracking entities
      else if(deviceExperience.error) return 'error'
      else if(!device.lastSeen || deviceExperience.date > device.lastSeen) return 'pending'       
      else if(deviceExperience.progress < 100) return 'syncing' + (device.networkStatus ? ' badconn' : '')
      else return 'ready'      
    },
    getPathPrefixes(exp){ 
      let tmp = JSON.parse(exp.structure)
      return { 
        webContentRootPath: exp.webRoot ?? tmp.webContentRootPath,
        deviceRootPath: '/VRData/' + tmp.menuFile.split('/')[0] + '/'
      }
    },
    calcFileCount(device, deviceExp){
      let tmp = {
        correctFiles: [],
        brokenFiles: []    
      }
      var exp = this.$store.state.experiences?.find(e => e.id == deviceExp.experienceId)
      if(exp) {
        let pathPrefixes = this.getPathPrefixes(exp)
        let deviceFileList = device.fileList?.filter(f => f.key.startsWith(pathPrefixes.deviceRootPath))
        
        exp.fileList?.forEach(sessionFile =>{
          let fileKey = sessionFile.key.substring(pathPrefixes.webContentRootPath.length)          
          let deviceFile = deviceFileList?.find(f => f.key == `${pathPrefixes.deviceRootPath}${fileKey}`)
          if(deviceFile && deviceFile.size == sessionFile.size)
            tmp.correctFiles.push(deviceFile)
          else{
            tmp.brokenFiles.push({
              key: fileKey,
              size: deviceFile?.size,
              serverLastModified: sessionFile.lastModified,
              lastModified: deviceFile?.lastModified,
              reason: !deviceFile 
                ? 'missing'
                : `${deviceFile.size} instead of ${sessionFile.size} bytes`
            })
          }  
        })
      }
      deviceExp.correctFiles = tmp.correctFiles
      deviceExp.brokenFiles = tmp.brokenFiles      
      deviceExp.totalFileCount = exp?.fileList.length
    },
    withFileDetails(device){
      device.experiences?.forEach(de => {
        this.calcFileCount(device, de)
      })      
      return device.experiences ?? []      
    },
    formatGB(size){
      return +parseFloat(size/1024.0/1024.0/1024.0).toFixed( 1 )
    },
    setExperiencesFilters(){
      let foundIndex = this.columns.findIndex((c) => {
        return c.field == 'experiences'
      })        
      this.$set(this.columns[foundIndex].filterOptions, 'filterDropdownItems', this.$store.getters.orderedExperiences.map(e => ({value: e.id, text: e.name})))
    }, 
    expFilterFn(data, filterString) {
      var expId = parseInt(filterString)            
      return data.some(de => de.experienceId == expId)
    },
    experienceSearch(options, term){
      const searchTerm = term.toLowerCase()
      return options.filter(o => o.name.toLowerCase().includes(searchTerm))
    },
    removeExpDevice(id){      
      let index = this.$refs.datatable.selectedRows.findIndex(s => s.id == id) 
      this.$set(this.$refs.datatable.selectedRows[index], 'vgtSelected', false)    
    },
    closeAssignModal(){
      this.assignExperienceModal = false
      this.experienceIds = []
      if(this.assignDone)
        this.getDevices()
      this.assignDone = false
    },
    closeUnassignModal(){
      this.unassignExperienceModal = false
      this.experienceIds = []
      if(this.assignDone)
        this.getDevices()
      this.assignDone = false
    },
    async assignExperiences(){
      try{
        this.$nprogress.start()
        this.loading = true   
        this.error = undefined;
        let data = []
        this.$refs.datatable.selectedRows.forEach(device => {
          this.experienceIds.forEach(expId => {
            data.push({deviceId: device.id, experienceId: expId})
          })
        })
        await axios.post(`devices/experiences`, data)  
        this.assignDone = true
        this.$refs.snackbar.show('Experiences assigned successfully')        
      }
      catch(err){
        this.$refs.snackbar.show('Error when assigning experiences: ' + err)
        this.error = err
      }
      finally{
        this.loading = false
        this.$nprogress.done()
      }
    },
    async unassignExperiences(){
      try{
        this.$nprogress.start()
        this.loading = true   
        this.error = undefined;
        let data = []
        this.$refs.datatable.selectedRows.forEach(device => {
          this.experienceIds.forEach(expId => {
            data.push({deviceId: device.id, experienceId: expId})
          })
        })
        await axios.delete(`devices/experiences`, { data: data })  
        this.assignDone = true
        this.closeUnassignModal()
        this.$refs.snackbar.show('Experiences removed successfully')        
      }
      catch(err){
        this.$refs.snackbar.show('Error when removing experiences: ' + err)
        this.error = err
      }
      finally{
        this.loading = false
        this.$nprogress.done()
      }
    },
    async quickUnassignExperience(){
      try{
        this.$nprogress.start()
        this.loading = true   
        this.error = undefined
        let data = []        
        data.push({deviceId: this.tempDevice.id, experienceId: this.quickUnassign.experienceId})        
        await axios.delete(`devices/experiences`, { data: data })         
        this.tempDevice.experiences = this.tempDevice.experiences.filter(x => x.experienceId != this.quickUnassign.experienceId)
        this.getDevices()
        this.$refs.snackbar.show('Experience removed successfully')        
      }
      catch(err){
        this.$refs.snackbar.show('Error when removing experience: ' + err)
        this.error = err
      }
      finally{
        this.loading = false
        this.$nprogress.done()
      }
      this.quickUnassign = false
    },
    // async editSessions(userId){            
    //   let resp = await axios.get(`users/${userId}/sessions`)
    //   this.userSessions = resp.data
    //   this.tempDevice = this.devices.find(u => u.userId == userId)
    //   this.showUserSessionsModal = true
    // },
    // async unassignAllSessions(){
    //   await axios.delete(`users/${this.tempDevice.userId}/sessions`)
    //   this.userSessions = []
    //   this.showUserSessionsModal = false
    //   this.$refs.snackbar.show('Device successfully removed from all sessions')
    // },
    // async unassignAllSessionsBulk(){
    //   if(!confirm('Are you sure you want to bulk unassign all sessions of selected devices?'))
    //     return
    //   try{
    //     this.$nprogress.start()
    //     this.loading = true   
    //     this.error = undefined;
    //     for (let index = 0; index < this.$refs.datatable.selectedRows.length; index++) {
    //       if(this.$refs.datatable.selectedRows[index].userId)
    //         await axios.delete(`users/${this.$refs.datatable.selectedRows[index].userId}/sessions`)            
    //     }    
    //     this.$refs.snackbar.show('All selected devices successfully removed from all sessions')
    //   }
    //   catch(err){
    //     console.log("Error when bulk unassigning sessions: " + err)
    //     this.$refs.snackbar.show('Error when bulk unassigning sessions: ' + err)
    //     this.error = err
    //   }
    //   finally{
    //     this.loading = false
    //     this.$nprogress.done()
    //     this.userSessions = [] 
    //   }
    // },
    async syncWithMdm(){
      try{
        this.$nprogress.start()
        this.loading = true   
        this.syncing = true
        let resp = await axios.post(`devices/mdmsync?account=${this.mdmAccount}`)    
        this.devices = resp.data
      }
      catch(err){
        console.log("Error when syncing devices with MDM: " + err)
        this.error = err
      }
      finally{
        this.loading = false
        this.syncing = false
        this.$nprogress.done()
      }
    },
    async getOrganizations(){
      let resp = await axios({ url: "organizations" })
      this.organizations = resp.data
    },
    async removeDevices(){
      try{
        this.error = undefined
        this.$nprogress.start()
        this.loading = true   
        this.deviceDeleteCount = 0
        for (let index = 0; index < this.$refs.datatable.selectedRows.length; index++) {
          const device = this.$refs.datatable.selectedRows[index]      
          this.deviceDeleteCount++
          //console.log('deviceDeleteCount ' + this.deviceDeleteCount)
          await axios({ url: `devices/${device.id}`, method: "DELETE" })    
        }

        this.getDevices()        
      }
      catch(err){
        console.log("Error when removing devices: " + err);
        this.error = err;
        this.$refs.snackbar.show('A problem occurred when deleting devices')
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
        this.showDeleteModal = false
        this.deviceDeleteCount = undefined
      }
    },
    async editRow(id){      
      //this.tempDevice = JSON.parse(JSON.stringify(this.devices.find(s => s.id == id))) 
      try{
        this.error = undefined
        this.$nprogress.start()
        this.loading = true
        this.tempDevice = (await axios.get(`devices/${id}`)).data
        this.tempDeviceIdBeforeEdit = id
        this.tempDeviceNameBeforeEdit = this.tempDevice.name
        this.tempDeviceOrganizationIdBeforeEdit = this.tempDevice.organizationId
        this.editDeviceModal = true
        // let resp = await axios.get(`users/${this.tempDevice.userId}/sessions`)
        // this.userSessions = resp.data
      }
      catch(err){
        console.log("Error when loading device details: " + err);
        this.error = err;
        this.$refs.snackbar.show('A problem occurred when loading device details')
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    }, 
    async deleteRow(id){  
      let index = this.devices.findIndex(s => s.id == id)      
      this.$set(this.devices[index], 'vgtSelected', false)
      // the above is a workaround for a bug in vgTable checkbox handler, where only the second $set call is registered after manually unselecting with the checkbox
      this.devices.forEach(device => {        
        this.$set(device, 'vgtSelected', device.id == id ? true : false)
      });
      this.showDeleteModal = true
    }, 
    async getDevices(){
      try{
        this.$nprogress.start()
        this.loading = true
        let resp = await axios.get(`devices?orgId=${this.orgId}`) 
        this.devices = resp.data        
      }
      finally{
        this.loading = false
        this.$nprogress.done()
      }
    },  
    async addPicoDevice(){
      try{
        this.error = undefined
        this.$nprogress.start()
        this.loading = true
        // eslint-disable-next-line
        let resp = await axios({ url: "devices", data: this.newDevicesList, method: "POST" })
        resp.data.forEach(newDevice => {
          newDevice.organization = this.organizations.find(o => o.id == newDevice.organizationId)
          if(newDevice.organizationId == this.orgId || (newDevice.organizationId == null && this.orgId == -1))
            this.devices.unshift(newDevice)
        });        
        this.resetAddDeviceFields()
        this.newDevicesCsv = ''
        if(this.syncAfterCreate)
          this.syncWithMdm()
        this.$refs.snackbar.show(`Devices added successfully! ${(this.syncAfterCreate ? 'Syncing with MDM started.' : '')}`)
      }
      catch(err){
        console.log("Error when adding device: " + err.response)
        this.error = err
        this.$refs.snackbar.show('A problem occurred when updating device')
      }
      finally{
        if(!this.syncAfterCreate)
          this.loading = false
        this.$nprogress.done()
        this.addPicoModal = false
      }
    }, 
    async addQuestDevice(){      
      try{
        this.error = undefined
        this.$nprogress.start()
        this.loading = true

        // connect SignalR
        if(!this.connection){
          let temptokenvar = this.$store.state.auth.token;
          this.connection = this.connection ?? new HubConnectionBuilder()
            .withUrl(process.env.VUE_APP_API_URL + 'devicehub?quest',{
                accessTokenFactory: () => temptokenvar
            })
            .withAutomaticReconnect()
            .build();        
          this.connection.on("QuestDeviceRegistered", this.questDeviceRegistered)
        }
        if (this.connection.state === HubConnectionState.Disconnected)
          await this.connection.start()

        // create placeholder
        await axios.post("devices/placeholder", { ...this.newDevice, experienceIds: this.questExperienceIds }) 
        
        // wait with timer until device registers itself
        this.addQuestTimer = 5 * 60;
        this.addQuestTimerInterval = setInterval(() => {
          if(this.addQuestTimer)
            this.addQuestTimer--
          if(!this.addQuestTimer){
            clearInterval(this.addQuestTimerInterval)
            if(this.addQuestTimer == 0)
              this.$refs.snackbar.show('This pairing session has expired. Please try again.')            
          }
        }, 1000)
      }
      catch(err){
        console.log("Error when adding device: " + err.response)
        this.error = err
        this.$refs.snackbar.show('A problem occurred when adding device')
      }
      finally{
        this.loading = false
        this.$nprogress.done()
      }

      // handler will be signalR when device registered if(addQuestModal == true && ....)
    },
    questDeviceRegistered(id){      
      if(this.addQuestModal == true && id.startsWith(this.newDevice.id)){
        clearInterval(this.addQuestTimerInterval)
        this.addQuestStep = 3
        this.addQuestTimer = null
        this.$refs.snackbar.show('Quest device with ID ' + id + ' successfully registered')   
        this.getDevices()
      }
    },    
    resetAddDeviceFields(){
        this.newDevice = {
          id: undefined,
          name: undefined
        }
        // actually "remember" these below, as users might often assign same for multiple devices when registering
        //this.newDevice.organizationId = null
        //this.questExperienceIds = []      
    },
    async updateDevice(){      
      try{        
        this.error = undefined
        this.$nprogress.start()
        this.loading = true
        // eslint-disable-next-line
        let resp = await axios({ url: `devices/${this.tempDeviceIdBeforeEdit}`, data: this.tempDevice, method: "POST" })
           
        let tmp = this.devices.find(d => d.id == this.tempDeviceIdBeforeEdit)      
        tmp.id = resp.data.id
        tmp.name = resp.data.name    
        tmp.userId = resp.data.userId
        tmp.created = resp.data.created
        tmp.lastLogin = resp.data.lastLogin
        tmp.organizationId = resp.data.organizationId
        tmp.organizationName = this.organizations.find(o => o.id == resp.data.organizationId).name    
        
        this.$refs.snackbar.show('Device updated successfully!')
      }
      catch(err){
        console.log("Error when updating device: " + err.response)
        this.error = err
        this.$refs.snackbar.show('A problem occurred when updating device')
      }
      finally{
        this.loading = false
        this.$nprogress.done()
        this.editDeviceModal = false
      }
    }, 
    getBatteryClass(mdmStatus){
      if(mdmStatus.charging == 'Charging')
        return 'charging'
      else if (Number(mdmStatus.battery) >= 90)
        return 'high'
      else if (Number(mdmStatus.battery) >= 50)
        return 'medium'
      else
        return 'low'
    },
    rowStyleClassFn(row) {
      let classes = row.mdmStatus?.connectionStatus == 'Online' ? 'online' : 'offline'
      if(!row.userId)
        classes += ' unlinked'
      if(!row.mdmId)
        classes += ' nomdm'
      if(row.syncStatus == 4)
        classes += ' action'
      return classes
    },
    async refreshMdm(mdmOnly){
      try{
        this.mdmloading = true
        // sync status
        if(!mdmOnly){
          //await this.getDevices()
          let resp = await axios.get(`devices?orgId=${this.orgId}`) 
          for (let i = 0; i < resp.data.length; i++) {
            let ds = resp.data[i]
            let device = this.devices.find(d => d.id == ds.id)          
            if(device){
              this.$set(device, 'sessionCount', ds.sessionCount)
              this.$set(device, 'experiences', ds.experiences)
              this.$set(device, 'freeStorage', ds.freeStorage)
              this.$set(device, 'lastSeen', ds.lastSeen)
              this.$set(device, 'syncStatus', ds.syncStatus)
              this.$set(device, 'syncPaused', ds.syncPaused)
              this.$set(device, 'networkStatus', ds.networkStatus)
              this.$set(device, 'vgtSelected', this.$refs.datatable.selectedRows.find(x => x.id == device.id) ? true : false) // prevent buggy vgtable to deselect on item update
            }
          }  
        }
        // mdm info  
        let resp = await axios({ url: `devices/mdmdevicestatus?orgId=${this.orgId}` })
        for (let i = 0; i < resp.data.length; i++) {
          let status = resp.data[i]
          let device = this.devices.find(d => d.mdmId == status.mdmId)          
          if(device)
            this.$set(device, 'mdmStatus', status)
        }    
      } 
      finally{
        this.mdmloading = false
      }
    }
  }, 
  async mounted() {
    try{
      await this.getDevices()
      await this.getOrganizations()
      this.setExperiencesFilters()
      this.mdmAccount = (this.$store.getters.organizationId == process.env.VUE_APP_INSEAD_ORG_ID) ? 'Insead' : 'Avris'
      this.refreshMdm(true)
    }
    finally{
      this.$store.state.showLoader = false
    }
  },
  created() {
    if(!this.tickerInterval)
      this.tickerInterval = setInterval(() => this.refreshMdm(), 60000)      
  },
  beforeDestroy: function(){
    if(this.tickerInterval)
      clearInterval(this.tickerInterval)    
  }
}
</script>

<style lang="scss">
main.devices3{  
  display: block;
  position: relative;
  --main-offset-vertical: 120px;

  .showbulkactions{
    --main-offset-vertical: 160px;
  }

  .newdevice{
    display: flex;

    input[type=text], select{
      // max-width: 25%;
      // float: left;
      flex-grow: 1;
      margin-left: 10px;
    }  

    button.insead{
      flex: none;
    }  
  }

  .deletedevicemodal{
    .modal-container{
      width: 600px;
    }
    .modal-footer{
      padding-top: 35px;

      > div{
        display: flex;
        justify-content: space-between;
      }

      button.insead{
        width: 270px;    
      }
    }
  }

  .devicemodal{
    .steps{
      display: flex;
      gap:30px;
      justify-content: center;
      margin: 40px 0 32px 0;

      .step{
        color: #777;
        text-align: center;

        span{
          display: inline-block;
          border-radius: 50px;
          border: 2px solid #00684b;
          text-align: center;
          width: 40px;
          height: 40px;
          line-height: 36px;
          color: #00684b;
          font-size: 23px;
          margin-bottom: 6px;
        }

        &.active{
          color: black;
          span{
            color: #fff;
            background-color: #00684b;
          }
        }

        &:last-child:not(.active){
          color: #777;
          span{
            color: #777;
            border-color: #777;
          }
        }
      }

      .avrisync{
        float:right;
        label{
          float: none;
          margin: unset;
        }
      }
    }

    .modal2-footer .stepper{
      margin: 8px 0 52px 0;
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      color: #777;

      img{
        width: 16px;
        margin-top:6px;
      }

      .step{
        text-align: center;

        img{
          width: 28px;
          opacity: 0.4;
          margin-top: 0;
        }
        &.active{
          opacity: 1;
          color: #000;
          img{
            opacity: 1;
          }
        }
      }
      
    }
  }

  span.newuseremail{
    display: inline-block;
    padding: 5px;
    border-radius: 3px;
    margin-right: 5px;
    margin-top: 5px;
    background-color: $green2;
    color: white;
  }

  h2{
    margin: 0 0 10px 0;
    //font-size: 20px;    
    font-weight: bold;
  }

  .buttons{
    display:flex; 
    justify-content: space-between; 
    align-items: flex-end; 
    margin-bottom: 14px;

    input{
      width: 300px;
      background-image: url(../assets/magnifier.svg);
      background-position: calc(100% - 6px) center;
      background-repeat: no-repeat;
      padding-right: 40px;
      background-size: 12px;
    }
  }

  .scrollbox{
    height: calc(100lvh - var(--size-header) - 150px - 70px);
    overflow-y: auto;    
    padding-right: 10px;
    margin-right: -13px;

    .vgt-wrap{
      border-radius: 8px;
      border: solid 1px #d7d7d8;
    }
  }

  .vgt-table{
    border-radius: 8px;
    border: none;

    tr{
      cursor: unset;
    }
    td{
      //padding: 5px 8px;
      //border: 1px solid #dcdfe6;         
      white-space: nowrap;
    }
  }

  .rowActions{
    display: flex;
    justify-content: space-around;

    button{
      width:16px;
      height: 16px;
      min-width: unset;

      img{
        width:16px;
        height: 16px;
      }
    }
  }

  .formerror{
    position: absolute;
    top: 0;
    left: 88px;
    right: 88px;
  }

  span.sync{
    &::before{
      content: '⬤';
      padding-right:5px;
    }
    &.ready{
      &::before{
        color: #51d247;
      }
      &::after{
        content: 'Up-to-date';
      }
    }   
    &.syncing{
      &::before{
        color: #4a92ff;
      }
      &::after{
        content: 'Syncing';
      }

      &.badconn{
        &::before{
          color: #ff9447;
        }
        &:not(.notext)::after{
          content: 'Syncing - Slow Connection'
        }
      }
    }  
    &.pending{
      &::before{
        color: #a2a2a2;
      }
      &::after{
        content: 'Awaiting Sync';
      }
    }   
    &.error{
      &::before{
        color: #ff4141;
      }
      &::after{
        content: 'Sync Issue';
      }
    }      
    &.paused{
      &::before{
        content: '';
        width: 15px;
        height: 14px;
        display: inline-block;
        background: url(../assets/pause-orange.svg) left no-repeat;
        background-size: contain;
        vertical-align: middle;
      }
      &::after{
        content: 'Paused';
        vertical-align: middle;
      }
    }
    &.notext::after{
      content: '';
    }
    &.action{
      &::before{
        content: '';
        width: 15px;
        height: 13px;
        display: inline-block;
        background: url(../assets/exclamationmark.svg) left no-repeat;
      }
      &::after{
        content: 'Requires action';
      }
    }   
    
    & + .progress{
      display: none;
    }
    &.syncing + .progress{
      display: inline;
    }
  }

  button.vs__deselect{
    border-radius: unset;
    background-color: unset;
    border: unset;
    padding: unset;
    height: unset;
    min-width: unset;
  }

  .v-select .vs__dropdown-toggle{
    height: unset;
  }

  .explabel{
    color: #2a836a;
    border: 1px solid #9dc3b9;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 10px;
    background-color: rgba(79, 150, 130, 0.05);
    margin: 2px;
    white-space: nowrap;
    float: left;
  }

  .expremovemodal{
    .expunassign{
      height: 300px; 
      --vs-dropdown-max-height: 200px;
      $vs-dropdown-max-height: 200px;
      .vs__dropdown-menu{
        max-height: 200px;
      }
    }
  }

  .expmodal{ 
    .modal2-container{
      max-width: unset;
      width: 70%;
    }
    .modal2-body{
      padding-right: unset;
    }

    .expassign{
      display:grid;  
      column-gap: 50px;
      row-gap: 20px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;

      .leftside{
        grid-column-start: 1;
        grid-row-start: 1;
      }

      .note{
        grid-column-start: 1;
        grid-row-start: 2;
        align-self: end;
      }
      
      .rightside{ 
        grid-column-start: 2;
        grid-column-end:  3;
        grid-row: 1 / 3;

        .avristable{
          overflow-y: auto;
          max-height: calc(100vh - 300px);

          table{
            th:last-child, td:last-child{
              width: 60px;
              text-align: center;
            }  

            td:last-of-type{
              display: flex;
              justify-content: center;
            }
            
            td.nospace{
              color: #ff852d;
            }
          }    

          .tooltip{
            position: static;

            .tooltiptext{                            
              right: 10%;
              left: unset;
              top: 30%;
              transform: unset;
              height: fit-content;
            }
          }
        }
      }

      .vs__dropdown-option--deselect{        
        background-color: #5897fb;
      }
      
    }
  }

  span.files{
    cursor: pointer;
    border-radius: 3px;
    &:hover{
      background-color: #dcdcdc;
      color: black;
    }
  }

  .avristable{
    border: 1px solid #ddd;
    border-radius: 8px;

    table{        
      border-collapse: separate;
      border-left: 0;      
      border-spacing: 0px;
      color: #777777;
      font-weight: normal;      
      width: 100%;

      button.icon{
        height: 16px;
        margin-top:2px;
        &[disabled]{
          opacity: 0.25;
          &:hover{
            background-color: transparent;
          }
        }
        img{
          width:16px;
        }
      }

      thead {
          display: table-header-group;
          vertical-align: middle;
          border-color: inherit;
          border-collapse: separate;
      }
      th{
        background-color: #eef2f5;      
      }
      tr {
          display: table-row;
          vertical-align: inherit;
          border-color: inherit;
      }
      th, td {
          padding: 3px 6px;
          text-align: left;
          vertical-align: middle;
          border-left: 1px solid #ddd;    
          white-space: nowrap;

          &:first-child{
            border-left: none;
          }
      }
      td {
          border-top: 1px solid #ddd;    
      }

      thead:first-child tr:first-child th:first-child, tbody:first-child tr:first-child td:first-child {
          border-radius: 8px 0 0 0;
      }
      thead:last-child tr:last-child th:first-child, tbody:last-child tr:last-child td:first-child {
          border-radius: 0 0 0 8px;
      } 
    }
  }

  .connection{
    &::before{
      content:'';
      width:12px;
      height: 12px;
      display: inline-block;
      border-radius: 20px;
      background-color: #4cad82;
      margin-right:9px;
    }
  }
  
  .offline{
    .connection::before{
      background-color: lightgray;
      font-weight: bold;
    }
    .power{
      opacity: 0.3;
    }
  }

  .power{
    &::before{
      content:'';
      width:20px;
      height: 20px;
      display: inline-block;
      background: url(../assets/low-battery.svg) no-repeat;
      vertical-align: middle;
    }

    &.medium::before{
      background: url(../assets/medium-battery.svg) no-repeat;
    }

    &.high::before{
      background: url(../assets/high-battery.svg) no-repeat;
    }

    &.charging::before{
      background: url(../assets/charge-battery.svg) no-repeat;
    }
  }

  @media (min-width: 1920px){
    --main-offset-vertical: 135px;

    .showbulkactions{
      --main-offset-vertical: 175px;
    }

    .explabel{
      padding: 3px 8px;
      font-size: 11px;
    }

    .buttons input{
      background-size: 16px;
    }

    .rowActions {
      button, button img{
        width: 20px;
        height: 20px;      
      }
    }
  }
}
</style>
